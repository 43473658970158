import React, { useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import cls from './PsychologistSide.module.scss';
import ChatInfo from '../ChatInfo/ChatInfo';
import Messages from '../Messages/Messages';
import CloseImg from '../../images/close.svg';

function PsychologistSide({ selectedChat, onSelect }) {
	const archiveButtonRef = useRef(null);
	const isMobile = useMediaQuery({
		query: '(max-width: 375px)',
	});

	return (
		<>
			<div className={cls.row}>
				<h1 className={cls.title}>Чат с пользователем </h1>
				{isMobile ? (
					<button
						ref={archiveButtonRef}
						className={cls.close}
						type="button"
						aria-label="Завершить чат"
					>
						<img src={CloseImg} alt="Закрыть чат" />
					</button>
				) : (
					<button
						ref={archiveButtonRef}
						className={cls.close}
						type="button"
					>
						Завершить чат
					</button>
				)}
			</div>
			<div className={cls.messagesContainer}>
				{!isMobile && <ChatInfo selectedChat={selectedChat} />}
				<Messages
					selectedChat={selectedChat}
					onSelect={onSelect}
					archiveButtonRef={archiveButtonRef}
				/>
			</div>
		</>
	);
}

export default PsychologistSide;
