const faqs = [
	{
		question: 'Когда мне ответит психолог?',
		answer: 'Психолог отвечает в рабочее время с 10:00 до 22:00 (мск), суббота и воскресенье — выходные. Обрати внимание, что иногда психолог отвечает не сразу, а с небольшой задержкой, несколько раз в день. Психолог обязательно ответит тебе в течение нескольких часов.',
		index: 1,
	},
	{
		question: 'Как быть, если у меня несколько психологических проблем?',
		answer: 'Чаще всего так и бывает. Попробуй определить, какая из проблем волнует тебя больше всего. И именно её опиши наиболее подробно. По мере общения, появится возможность описать и остальные проблемы.',
		index: 2,
	},
	{
		question: 'Что делать, если психолог не отвечает?',
		answer: 'Бывает так, что наши психологи не могут ответить сразу. Не расстраивайся, психолог обязательно ответит позже. Если ответ не пришёл в течении 24 часов, отправь заявку повторно. А если у тебя срочный вопрос, можешь позвонить на любую горячую линию (смотри выше).',
		index: 3,
	},
	{
		question: 'Стоит ли писать, если у меня нет особых проблем?',
		answer: 'Конечно, стоит. К психологу обращаются не только с проблемами. Психолог может помочь, когда ты знаешь, чего хочешь, и тебе нужно узнать, как этого достичь. К психологу обращаются с целью самопознания или саморазвития, когда ты готов меняться и двигаться дальше. Психолог, подскажет как обрести гармонию и улучшить ментальное здоровье.',
		index: 4,
	},
	{
		question: 'Кто имеет доступ к тому, что я пишу в заявке?',
		answer: 'Все, что ты пишешь — анонимно и конфиденциально. Сообщения прочтёт психолог, который откликнется на заявку. Если ты не назовёшь себя, он не узнает, кто именно написал ему сообщение.',
		index: 5,
	},
];

export default faqs;
