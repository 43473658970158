import { memo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { NavLink, useLocation } from 'react-router-dom';
import chatIcon from '../../images/Chat_Circle_Dots.svg';
import fileIcon from '../../images/Files.svg';
import userIcon from '../../images/User_01.svg';
import chatIconActive from '../../images/chatIcon_active.svg';
import chatIconActiveMobile from '../../images/chatIcon_active_mobile.svg';
import fileIconActive from '../../images/filesIcon_active.svg';
import fileIconActiveMobile from '../../images/filesIcon_active_mobile.svg';
import userIconActive from '../../images/userIcon_active.svg';
import userIconActiveMobile from '../../images/userIcon_active_mobile.svg';
import styles from './Sidebar.module.scss';

const Sidebar = memo(() => {
	const location = useLocation();
	const isMobile = useMediaQuery({
		query: '(max-width: 375px)',
	});
	if (isMobile) {
		return (
			<div className={styles.sidebar} id="sidebar">
				<ul className={styles.links}>
					<li className={styles.list}>
						<NavLink to="/account-chat" className={styles.link}>
							<img
								src={
									location.pathname === '/account-chat'
										? chatIconActiveMobile
										: chatIcon
								}
								alt="значок"
								className={styles.icon}
							/>
						</NavLink>
					</li>
					<li className={styles.list}>
						<NavLink
							to="/account-principles"
							className={styles.link}
						>
							<img
								src={
									location.pathname === '/account-principles'
										? fileIconActiveMobile
										: fileIcon
								}
								alt="значок"
								className={styles.icon}
							/>
						</NavLink>
					</li>
					<li className={styles.list}>
						<NavLink to="/account" className={styles.link}>
							<img
								src={
									location.pathname === '/account' ||
									location.pathname === '/account/documents'
										? userIconActiveMobile
										: userIcon
								}
								alt="значок"
								className={styles.icon}
							/>
						</NavLink>
					</li>
				</ul>
			</div>
		);
	}
	return (
		<div className={styles.sidebar}>
			<div className={styles.box}>
				<ul className={styles.links}>
					<li className={styles.list}>
						<NavLink
							to="/account"
							className={`${styles.link} ${
								location.pathname === '/account' ||
								location.pathname === '/account/documents'
									? styles.active
									: ''
							}`}
						>
							<img
								src={
									location.pathname === '/account' ||
									location.pathname === '/account/documents'
										? userIconActive
										: userIcon
								}
								alt="значок"
								className={styles.icon}
							/>
							Профиль
						</NavLink>
					</li>
					<li className={styles.list}>
						<NavLink
							to="/account-chat"
							className={`${styles.link} ${
								location.pathname === '/account-chat'
									? styles.active
									: ''
							}`}
						>
							<img
								src={
									location.pathname === '/account-chat'
										? chatIconActive
										: chatIcon
								}
								alt="значок"
							/>
							Чат
						</NavLink>
					</li>
					<li className={styles.list}>
						<NavLink
							to="/account-principles"
							className={`${styles.link} ${
								location.pathname === '/account-principles'
									? styles.active
									: ''
							}`}
						>
							<img
								src={
									location.pathname === '/account-principles'
										? fileIconActive
										: fileIcon
								}
								alt="значок"
							/>
							Памятка
						</NavLink>
					</li>
				</ul>
			</div>
		</div>
	);
});

export default Sidebar;
