import { memo, useState } from 'react';
import faqs from '../../../utils/faq';
import FaqItem from '../FaqItem/FaqItem';
import styles from './FaqSection.module.scss';

const FaqSection = memo(() => {
	const [expanded, setExpanded] = useState({});

	const toggleExpanded = (index) => {
		setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
	};

	return (
		<section id="faqSection" className={styles.faqSection}>
			<h2 className={styles.mainSubHeader}>Часто задаваемые вопросы</h2>
			<div className={styles.faqBlock}>
				{faqs.map((faq) => (
					<FaqItem
						key={faq.index}
						question={faq.question}
						answer={faq.answer}
						isExpanded={expanded[faq.index]}
						toggle={() => toggleExpanded(faq.index)}
					/>
				))}
			</div>
		</section>
	);
});

export default FaqSection;
