import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../images/logo.svg';
import { ReactComponent as Menu } from '../../images/menu-icon.svg';
import { authActions } from '../../slices/authSlice/authSlice';
import { userActions } from '../../slices/userSlice/userSlice';
import Logo from '../Logo/Logo';
import Button from '../buttonHeader/Button';
import styles from './Header.module.scss';
import { burgerActions } from '../../slices/burgerSlice/burgerSlice';

const Header = memo(() => {
	const isLoggedIn = useSelector((state) => state.user.userData);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const isMobile = useMediaQuery({
		query: '(max-width: 375px)',
	});

	const handleNav = () => {
		if (location.pathname !== '/signin') {
			navigate('/signin');
		} else {
			window.location.reload();
		}
	};

	const handleLogout = useCallback(() => {
		dispatch(userActions.logout());
		dispatch(authActions.logout());
		navigate('/');
	}, [dispatch, navigate]);

	if (isMobile) {
		return (
			<header id="headerSection" className={styles.header}>
				<Menu
					onClick={() => dispatch(burgerActions.toggleMenu())}
					className={styles.menu}
				/>
				<Link to="/">
					<LogoIcon
						className={styles.logo}
						src={LogoIcon}
						alt="логотип"
						onClick={() => dispatch(burgerActions.closeMenu())}
					/>
				</Link>
			</header>
		);
	}
	return (
		<header id="headerSection" className={styles.header}>
			<nav className={styles.headerNav}>
				<Logo />
				<div className={styles.navLinks}>
					<NavLink to="/#aboutSection" className={styles.navLink}>
						О проекте
					</NavLink>

					<NavLink
						to="/#howItWorksSection"
						className={styles.navLink}
					>
						Как это работает
					</NavLink>

					<NavLink
						to={isLoggedIn ? '/account-chat' : '/welcome'}
						className={styles.navLink}
					>
						Психологам
					</NavLink>

					{!isLoggedIn && (
						<NavLink to="/#faqSection" className={styles.navLink}>
							Вопросы
						</NavLink>
					)}
					<form className={styles.selectForm}>
						<select name="lang" className={styles.langSelect}>
							<option value="Russian" selected>
								RU
							</option>
							<option value="English">EN</option>
						</select>
						<div className={styles.selectArrow} />
					</form>
				</div>

				<div className={styles.navAuthbar}>
					{isLoggedIn && location.pathname !== '/welcome' && (
						<Button
							additionalStyles={styles.logBtn}
							buttonText="Выйти"
							onClick={handleLogout}
						/>
					)}

					{!isLoggedIn &&
						location.pathname !== '/' &&
						(location.pathname === '/welcome' ||
							location.pathname === '/signup') && (
							<Button
								additionalStyles={styles.logBtn}
								buttonText="Войти"
								onClick={handleNav}
							/>
						)}

					{!isLoggedIn &&
						location.pathname === '/' &&
						location.pathname !== '/welcome' && (
							<Button
								additionalStyles={styles.mainBtn}
								buttonText="Обратиться за помощью"
								onClick={() => navigate('/#howItWorksSection')}
							/>
						)}
					{!isLoggedIn &&
						(location.pathname === '/signin' ||
							location.pathname === '/forgotpassword' ||
							location.pathname === '/waiting-room') && (
							<Button
								additionalStyles={styles.mainBtn}
								buttonText="Обратиться за помощью"
								onClick={() => navigate('/#howItWorksSection')}
							/>
						)}
				</div>
			</nav>
		</header>
	);
});

export default Header;
