import React, { memo, useCallback } from 'react';
import style from './SentDocuments.module.scss';

const SentDocuments = memo(({ documents }) => {
	const extractDocName = useCallback((url) => {
		// const lastSlashIndex = url.lastIndexOf('/');
		// return url.slice(lastSlashIndex + 1);
		const docName = url.match(/\/([^/?#]+)\.jpeg/);
		return `${docName[1]}.jpg`;
	}, []);

	return (
		<div className={style.body}>
			<h3 className={style.header}>Подтверждение навыков</h3>
			<div className={style.text}>
				<p className={style.p}>
					Благодарим за предоставленные документы. Мы хотели бы
					сообщить вам, что ваш запрос на валидацию навыков успешно
					получен. После проверки документов на подлинность, вам будет
					отправлено уведомление на&nbsp;электронную почту, указанную
					при регистрации. Пожалуйста, ожидайте результатами в течение
					24 часов.
				</p>
				<p className={style.p}>
					Спасибо за ваше терпение и понимание.
					<br />
					Мы надеемся, что результаты валидации будут положительными.
				</p>
			</div>
			<div className={style.docsContainer}>
				<h3 className={style.header}>Отправлены на верификацию</h3>
				<ul className={style.ul}>
					{documents.map((doc) => (
						<div className={style.listElement}>
							<img
								className={style.photo}
								src={doc.scan}
								alt="фото документа"
							/>
							<li className={style.listDocument}>
								{extractDocName(doc.scan)}
							</li>
						</div>
					))}
				</ul>
			</div>
		</div>
	);
});

export default SentDocuments;
