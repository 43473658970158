import React from 'react';
import styles from './FaqItem.module.scss';
import plus from '../../../images/plus.svg';

const FaqItem = ({ question, answer, isExpanded, toggle }) => (
	<div
		className={styles.faqElement}
		onClick={toggle}
		role="button"
		aria-hidden="true"
	>
		<div className={styles.faqQuestion}>
			<p className={styles.faqQuestionText}>{question}</p>
			<div
				className={`${styles.toggle} ${
					isExpanded ? styles.expanded : ''
				}`}
				onKeyDown={(event) => {
					if (event.key === 'Enter' || event.key === ' ') toggle();
				}}
				role="button"
				tabIndex="0"
			>
				<img className={styles.plus} src={plus} alt="plus" />
			</div>
		</div>
		<div
			className={`${styles.faqAnswer} ${
				isExpanded ? styles.expandedAnswer : ''
			}`}
		>
			<p className={styles.faqAnswerText}>{answer}</p>
		</div>
	</div>
);

export default FaqItem;
