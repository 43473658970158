import { memo } from 'react';
import styles from './WarningSection.module.scss';

const WarningSection = memo(() => {
	return (
		<section className={styles.warningSection}>
			<h2 className={`${styles.mainSubHeader} ${styles.boldLeft}`}>
				<span className={styles.highlightText}>Внимание! </span>
				Платформа не создана
				<br />
				для экстренной помощи.
			</h2>

			<p className={styles.mainText}>
				Если вы переживаете или испытываете острый эмоциональный кризис,
				позвоните на номер горячей линии или обратитесь в кризисный
				центр.
			</p>

			<div className={styles.featuresBlock}>
				<div className={styles.featuresElement}>
					<p className={styles.featuresText}>
						Горячая линия психологической помощи Московского
						института психоанализа
					</p>
					<p className={styles.featuresNumber}>
						+7&nbsp;(800)&nbsp;500&#8209;22&#8209;87
					</p>
				</div>

				<div className={styles.featuresElement}>
					<p className={styles.featuresText}>
						Кризисная линия доверия по России
					</p>
					<p className={styles.featuresNumber}>
						+7&nbsp;(800)&nbsp;333&#8209;44&#8209;34
					</p>
				</div>

				<div className={styles.featuresElement}>
					<p className={styles.featuresText}>
						Горячая линия службы &laquo;Ясное утро&raquo;
						<br />
						для онкологических больных и&nbsp;их&nbsp;близких
					</p>
					<p className={styles.featuresNumber}>
						+7&nbsp;(800)&nbsp;100&#8209;01&#8209;91
					</p>
				</div>

				<div className={styles.featuresElement}>
					<p className={styles.featuresText}>
						Горячая линия Центра экстренной психологической помощи
						МЧС России
					</p>
					<p className={styles.featuresNumber}>
						+7&nbsp;(495)&nbsp;989&#8209;50&#8209;50
					</p>
				</div>
			</div>
		</section>
	);
});

export default WarningSection;
