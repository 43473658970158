const principles = [
	{
		number: 1,
		title: 'Принцип анонимности и конфиденциальности',
		text: 'Психологи обязаны поддерживать полную конфиденциальность в отношении личных данных клиентов. Никакая информация, полученная в ходе консультаций, не должна быть раскрыта третьим лицам.',
	},
	{
		number: 2,
		title: 'Принцип этической практики',
		text: 'Все психологи, предоставляющие услуги на сайте, обязаны следовать высоким стандартам этической практики. Это включает в себя соблюдение кодекса этики, избегание конфликта интересов, а также честность и прозрачность в отношениях с клиентами.',
	},
	{
		number: 3,
		title: 'Принцип профессионального развития',
		text: 'Наши психологи обязаны постоянно совершенствовать свои навыки и знания в области психологии. Поддерживая активное профессиональное развитие, мы гарантируем высокий уровень качества предоставляемых услуг.',
	},
	{
		number: 4,
		title: 'Принцип культурной чувствительности',
		text: 'Мы ценим и уважаем многообразие культур, представленных нашими клиентами. Работая с клиентами разного происхождения, необходимо проявлять открытость, терпимость и уважение к их уникальным потребностям.',
	},
];

export default principles;
