import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	menuIsOpen: false,
};

export const burgerSlice = createSlice({
	name: 'burger',
	initialState,
	reducers: {
		toggleMenu: (state) => {
			state.menuIsOpen = !state.menuIsOpen;
		},
		closeMenu: (state) => {
			state.menuIsOpen = false;
		},
	},
});

export const { actions: burgerActions } = burgerSlice;
export const { reducer: burgerReducer } = burgerSlice;
