import { memo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as LogoIcon } from '../../../images/logo.svg';
import styles from './MainSection.module.scss';

const MainSection = memo(() => {
	const isMobile = useMediaQuery({
		query: '(max-width: 375px)',
	});

	return (
		<section className={styles.mainSection}>
			{isMobile && <LogoIcon className={styles.logo} />}
			<h1 className={styles.mainHeader}>Давай поговорим</h1>
			<div className={styles.textBlock}>
				<h2 className={styles.mainSubHeader}>
					Онлайн&ndash;сервис бесплатной психологической помощи
				</h2>
				<p className={styles.mainText}>
					Наша миссия &mdash; помочь тебе раскрыть внутреннюю силу,
					справляться с трудностями и жить в гармонии с собой.
				</p>
			</div>
		</section>
	);
});

export default MainSection;
