import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, NavLink } from 'react-router-dom';
import styles from './Footer.module.scss';

const Footer = memo(() => {
	const isLoggedIn = useSelector((state) => state.user.userData);
	const isMobile = useMediaQuery({
		query: '(max-width: 375px)',
	});

	if (isMobile) {
		return (
			<footer className={styles.footer}>
				<nav className={styles.footerNav}>
					<div className={styles.navLinks}>
						<NavLink to="/#aboutSection" className={styles.navLink}>
							О проекте
						</NavLink>

						<NavLink
							to="/#howItWorksSection"
							className={styles.navLink}
						>
							Как это работает
						</NavLink>

						<NavLink
							to={isLoggedIn ? '/account-chat' : '/welcome'}
							className={styles.navLink}
						>
							Психологам
						</NavLink>
						<NavLink to="/#faqSection" className={styles.navLink}>
							Вопросы
						</NavLink>
					</div>
				</nav>
				<div className={styles.footerCopyright}>
					<p className={styles.copyRight}>
						&copy; 2024 Давай поговорим
					</p>
					<Link className={styles.privacyLink} to="/privacy-policy">
						Политика конфиденциальности
					</Link>
				</div>
			</footer>
		);
	}

	return (
		<footer className={styles.footer}>
			<div className={styles.footerContainer}>
				<p className={styles.copyRight}>&copy; 2024 Давай поговорим</p>
				<nav className={styles.footerNav}>
					<div className={styles.navLinks}>
						<NavLink to="/#aboutSection" className={styles.navLink}>
							О проекте
						</NavLink>
						<NavLink
							to="/#howItWorksSection"
							className={styles.navLink}
						>
							Как это работает
						</NavLink>
						<NavLink
							to={isLoggedIn ? '/account-chat' : '/welcome'}
							className={styles.navLink}
						>
							Психологам
						</NavLink>

						{!isLoggedIn && (
							<NavLink
								to="/#faqSection"
								className={styles.navLink}
							>
								Вопросы
							</NavLink>
						)}

						<NavLink
							to={isLoggedIn ? '/welcome' : '/signin'}
							className={styles.navLink}
						>
							Личный кабинет психолога
						</NavLink>
						<form className={styles.selectForm}>
							<select name="lang" className={styles.langSelect}>
								<option value="Russian" selected>
									RU
								</option>
								<option value="English">EN</option>
							</select>
							<div className={styles.selectArrow} />
						</form>
					</div>
				</nav>
			</div>
		</footer>
	);
});

export default Footer;
