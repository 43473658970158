import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { authActions } from '../../slices/authSlice/authSlice';
import { burgerActions } from '../../slices/burgerSlice/burgerSlice';
import { userActions } from '../../slices/userSlice/userSlice';
import Button from '../buttonHeader/Button';
import cls from './BurgerMenu.module.scss';

const BurgerMenu = () => {
	const isLoggedIn = useSelector((state) => state.user.userData);
	const burger = useSelector((state) => state.burger);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleLogout = () => {
		dispatch(userActions.logout());
		dispatch(authActions.logout());
		dispatch(burgerActions.closeMenu());
		navigate('/');
	};

	const handleLogin = () => {
		navigate('/signin');
		dispatch(burgerActions.closeMenu());
	};

	return (
		<nav className={clsx(cls.burger, { [cls.active]: burger.menuIsOpen })}>
			<ul className={cls.navLinks}>
				<li>
					<NavLink
						to="/#aboutSection"
						onClick={() => dispatch(burgerActions.closeMenu())}
						className={cls.navLink}
					>
						О проекте
					</NavLink>
				</li>
				<li>
					<NavLink
						to="/#howItWorksSection"
						className={cls.navLink}
						onClick={() => dispatch(burgerActions.closeMenu())}
					>
						Как это работает
					</NavLink>
				</li>
				<li>
					<NavLink
						to={isLoggedIn ? '/account-chat' : '/welcome'}
						className={cls.navLink}
						onClick={() => dispatch(burgerActions.closeMenu())}
					>
						Психологам
					</NavLink>
					{isLoggedIn && (
						<li>
							<Button
								additionalStyles={cls.logBtn}
								buttonText="Выйти"
								onClick={handleLogout}
							/>
						</li>
					)}
				</li>
				{!isLoggedIn && (
					<>
						<li>
							<NavLink
								to="/#faqSection"
								className={cls.navLink}
								onClick={() =>
									dispatch(burgerActions.closeMenu())
								}
							>
								Вопросы
							</NavLink>
						</li>
						<li>
							<Button
								additionalStyles={cls.logBtn}
								buttonText="Вход для психолога"
								onClick={handleLogin}
							/>
						</li>
					</>
				)}
			</ul>
		</nav>
	);
};

export default BurgerMenu;
